import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {},
    de: {
      translation: {
        Login: "Einloggen",
        "Official E-Mail Address": "Offizielle E-Mail Adresse",
        Password: "Passwort",
        "Enter Club": "Klub betreten",
        "Forgot Password?": "Passwort vergessen?",
        Registration: "Registrieren",
        "Wrong Email or Password":
          "Falsche E-Mail-Adresse oder falsches Passwort.",
        "Claims start":
          "Indem Sie sich anmelden oder registrieren, bestätigen Sie, dass Sie die nachstehenden Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinien von Klub gelesen und verstanden haben und ihnen zustimmen.",
        "Terms and Conditions": " Allgemeinen Geschäftsbedingungen",
        and: " und",
        "Privacy Policy": " Datenschutzrichtlinien",
        "Claims end":
          " Datenschutzrichtlinien von Klub gelesen und verstanden haben und ihnen zustimmen.",
        "Request password": "Passwort anfordern",
        "Email is required": "E-Mail ist erforderlich",
        "Invalid email format": "Ungültiges E-Mail-Format",
        "Error while trying to register": "Fehler beim Registrieren",
        Congrats: "Großartig!",
        "You have successfully registered our team will contact you wia email":
          "Unser Team überprüft deine Registrierung. Wenn wir keine Rückfragen haben, bekommst du in den nächsten Tagen eine E-Mail zugeschickt. Dort findest du den Login-Link und dein Passwort.",
        Events: "Angebote",
        Location: "Einrichtung",
        Settings: "Einstellungen",
        "Events overview": "Angebotsübersicht",
        Event: "Angebot",
        Category: "Kategorie",
        Day: "Tag(e)",
        Registrations: "Anmeldungen",
        "Create new event": "+ Neues Angebot erstellen",
        "Check-in": "Check-in",
        "Create event": "Neues Angebot",
        Save: "Speichern",
        "Event name": "Name des Angebots",
        "Event Category": "Kategorie des Angebots",
        "Short Description": "Kurze Beschreibung",
        "Target Group": "Zielgruppe",
        All: "Alle",
        Boys: "Jungs",
        Girls: "Mädchen",
        MINTA: "MINTA",
        LGBTQI: "LGBTIQ+",
        Age: "Alter",
        From: "Von*",
        To: "Bis*",
        When: "Wann",
        Regular: "Regelmäßig",
        Unique: "Einmalig",
        Price: "Preis",
        "Klub for School": "Klub für Schule",
        "Free of charge": "Kostenlos",
        Euro: "Euro",
        "Key words for search": "Stichworte für die Suche, kommagetrennt",
        "Hero Photo": "Hero Foto",
        "Image picker description":
          "Bitte lade ein Bild hoch, das das Angebot beschreibt. Sonst nehmen wir ein KI-generiertes Bild.",
        "Image format": "Format: Smartphone Hochkant.",
        "IA-description":
          "KI-TEXTE - diese Funktion wird in der Zukunft möglich sein.",
        "IA-photo":
          "KI-FOTOS - diese Funktion wird in der Zukunft möglich sein.",
        "Share event": "Mit verknüpften Konten teilen",
        Instagram: "Instagram",
        Website: "Website",
        "Sharing tooltip":
          "SHARING - diese Funktion wird in der Zukunft möglich sein.",
        "Required field": "* Pflichtfelder",
        "Required fields is not filled": "Pflichtfelder sind nicht ausgefüllt",
        "Log out": "Ausloggen",
        "E-Mail": "E-Mail Adresse",
        New: "Neu",
        Repeat: "Wiederholen",
        Old: "Alt",
        "Change Password": "Passwort ändern",
        "Save Password": "Passwort speichern",
        "Save Email": "E-Mail speichern",
        "Email does not match": "Die E-Mail stimmen nicht überein.",
        "Password does not match": "Die Passwörter stimmen nicht überein.",
        "Email updated": "Dein E-Mail wurde erfolgreich geändert.",
        "Password updated": "Dein Passwort wurde erfolgreich geändert.",
        "Opps something went wrong": "Opps, etwas ist schief gelaufen.",
        "Old password is incorrect": "Das alte Passwort ist falsch.",
        "New password is too short": "Das neue Passwort ist zu kurz.",
        "Location Name": "Name der Einrichtung (erscheint in App) *",
        "Street and house number": "Straße & Hausnummer *",
        "Postal code": "PLZ *",
        City: "Stadt *",
        Area: "Bezirk *",
        "Short description": "Kurze Beschreibung - was macht euch besonders?",
        "General target group": "Zielgruppe allgemein *",
        "General age": "Alter allgemein *",
        "Opening hours": "Öffnungszeiten",
        "Location type": "Einrichtungart *",
        "Barrier-free access?": "Barrierefreier Zugang? *",
        Holder: "Träger *",
        "Facility manager": "Einrichtungleiter*in *",
        "Contact person": "Klubmeister*in (Ansprechpartner*in) *",
        "Phone number": "Telefon (erscheint in App) *",
        "Mobile number": "Mobil (erscheint nicht in App)",
        Email: "Email *",
        "Instagram-Handle": "Instagram-Handle",
        "Tiktok-Handle": "Tiktok-Handle",

        "Location Photo": "Foto der Einrichtung",
        "Location Image picker description":
          "Bitte lade ein Bild hoch, das die Einrichtung von außen zeigt, so dass Jugendliche sie erkennen.",
        "Location Image format": "Querformat",
        "Location saved successfully": "Einrichtung erfolgreich gespeichert",
        Date: "Datum",
        Checkins: "Checkins",
        Name: "Name",
        "Visited?": "Anwesend?",
        "No users": "Keine Nutzer",
        "Are you sure?": "Bist du sicher?",
        "This event would be deleted": "Damit löscht du dieses Angebot.",
        "Restore?": "Wiederherstellen?",
        "Restore canceled event":
          "Damit stellst du das gelöschte  Angebot wieder her.",
        "No I don't want": "DOCH NICHT",
        "Yes please": "JA, BITTE",
        Edit: "Bearbeiten",
        "Duration (min)": "Dauer (min)",
        "All clear!": "Alles klar!",
        "We sent a password to your email":
          "Wir haben dir eine E-Mail mit einem neuen Passwort geschickt.",
        "If you have any problems, technical errors or new ideas, please feel free to contact us by email. We usually respond the same day.":
          "Bei Problemen, technischen Fehlern oder neuen Ideen, meldet euch supergern per E-Mail. Wir antworten normalerweise am selben Tag.",
        "Do you have any questions?":
          "Hast du Fragen oder Probleme? Meld dich bei:",
        "End date should be after start date":
          "Das Enddatum sollte nach dem Startdatum liegen.",
        "Min age should be less than max age":
          "Das Mindestalter sollte kleiner als das Maximalalter sein.",
        "Opening hour": "Öffungszeiten: Tage & Uhrzeit *",
        "Future events": "Zukünftige Angebote",
        "Past events": "Vergangene Angebote",
      },
    },
  },
  lng: "de",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
